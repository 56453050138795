var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("h1", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("views.announcements_lite.budget.title")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "instructions" }, [
                _vm._v(
                  "\n        Utilizá el siguiente editor de texto para redactar y presupuestar tu\n        aviso. También podés copiar y pegar desde tu editor de texto favorito.\n      "
                )
              ]),
              _vm._v(" "),
              _c("ValidationObserver", {
                ref: "form",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var handleSubmit = ref.handleSubmit
                      return [
                        _c(
                          "v-form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return handleSubmit(_vm.onSubmit)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pb-4" },
                              [
                                _c("ValidationProvider", {
                                  attrs: { vid: "content" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("ckeditor", {
                                              attrs: {
                                                editor: _vm.editor,
                                                config: _vm.editorConfig,
                                                disabled: _vm.disabled
                                              },
                                              model: {
                                                value: _vm.object.content,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.object,
                                                    "content",
                                                    $$v
                                                  )
                                                },
                                                expression: "object.content"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "error--text" },
                                              [
                                                _vm._v(
                                                  _vm._s(errors.join(", "))
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              { staticClass: "pb-4" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "h3",
                                      { staticClass: "text-uppercase" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "attributes.announcement.annexes"
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("TusVueFileAgentMultipleProvider", {
                                      attrs: {
                                        vid: "annexes",
                                        label: _vm.$t("attributes.annex.file"),
                                        disabled: _vm.disabled,
                                        editable: false,
                                        field: "annexes",
                                        value: _vm.annexesValue,
                                        accept: _vm.accept,
                                        "max-size": "100MB",
                                        filename: function(resource) {
                                          return (
                                            resource.name +
                                            "." +
                                            resource.file.extension
                                          )
                                        }
                                      },
                                      on: {
                                        "file:uploaded": function(file) {
                                          return _vm.addAnnex(file)
                                        },
                                        "file:deleted": function(file) {
                                          return _vm.removeAnnex(file)
                                        },
                                        "files:uploading": function($event) {
                                          return _vm.disableSubmit()
                                        },
                                        "files:uploaded": function($event) {
                                          return _vm.enableSubmit()
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  type: "submit",
                                  disabled: _vm.disabled,
                                  tile: ""
                                }
                              },
                              [_vm._v("\n            Presupuestar\n          ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-2",
                                attrs: {
                                  text: "",
                                  color: "primary",
                                  type: "button",
                                  tile: ""
                                },
                                on: { click: _vm.reset }
                              },
                              [_vm._v("\n            Reiniciar\n          ")]
                            ),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              attrs: { vid: "recaptchaToken" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("VueRecaptcha", {
                                          ref: "recaptcha",
                                          attrs: {
                                            sitekey:
                                              _vm.$config.recaptcha.siteKey,
                                            size: "invisible"
                                          },
                                          on: {
                                            verify: _vm.onCaptchaVerified,
                                            expired: _vm.onCaptchaExpired
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "error--text" },
                                          [_vm._v(_vm._s(errors.join(", ")))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.budgeted
                ? _c("BudgetTable", {
                    attrs: { announcement: _vm.announcementLite }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }