var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _vm.announcement
                    ? _c("AnnouncementBudget", {
                        attrs: { announcement: _vm.announcement }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-right", attrs: { cols: "12" } },
                [
                  _c("v-btn", { attrs: { text: "" } }, [_vm._v("Descartar")]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success" },
                      on: {
                        click: function($event) {
                          return _vm.acceptAndContinue()
                        }
                      }
                    },
                    [_vm._v("Aceptar y continuar")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _vm.fee
                  ? _c("div", [_vm._v("\n          Tasa\n        ")])
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }