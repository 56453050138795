import APILibrary from "./APILibrary";
import BaseResource from "./BaseResource";

class Annex extends BaseResource {
  static define() {
    this.attributes("name", "file");

    this.belongsTo("announcement");
  }

  /*
    Por defecto las propiedades se transforman a camelCase
  */
  toString() {
    return `${this.name}`;
  }

  hasFile() {
    return this.file != undefined && Object.keys(this.file).length !== 0;
  }
}

export default APILibrary.createResource(Annex);
