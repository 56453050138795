<template>
  <fragment>
    <!-- <AuthorValidationForm v-if="announcement == null" :myMethod="tryOut" /> -->
    <v-container>
      <!-- <v-row>
        <v-col cols="12">
          <h1>Texto del aviso</h1>
          <div v-html="announcement.content"></div>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="12">
          <AnnouncementBudget :announcement="announcement" v-if="announcement" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn text>Descartar</v-btn>
          <v-btn color="success" @click="acceptAndContinue()">Aceptar y continuar</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div v-if="fee">
            Tasa
          </div>
        </v-col>
      </v-row>
    </v-container>
  </fragment>
</template>

<script>
import AnnouncementBudget from "@/components/public/announcements/AnnouncementBudget";
import Announcement from "@/resources/Announcement";
import Fee from "@/resources/Fee";

export default {
  components: {
    AnnouncementBudget,
  },
  data: () => {
    return {
      announcement: null,
      fee: null
    };
  },
  async created() {
    this.announcement = await Announcement.findBy({
      uuid: this.$route.params.uuid,
    });
  },
  methods: {
    async acceptAndContinue() {
      const fee = Fee.build({ announcementUuid: this.$route.params.uuid })

      try {
        await fee.save();

        this.$router.push({ name: "public-fees-show", params: { uuid: this.$route.params.uuid } });
      } catch {
        console.log('error')
      }
    },
  },
};
</script>
