import New from "@/pages/public/announcements/New";
import Show from "@/pages/public/announcements/Show";

const announcementRoutes = [
  {
    path: "/avisos/crear",
    name: "public-announcements-new",
    component: New,
    meta: { resource: "Announcement", action: "create" },
  },
  {
    path: '/avisos/:uuid',
    name: 'public-announcements-show',
    component: Show,
    meta: { resource: 'Announcement', action: 'show' },
  },
];

export default announcementRoutes;
