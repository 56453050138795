import APILibrary from "@/resources/APILibrary";
import BaseResource from "@/resources/BaseResource";

class Fee extends BaseResource {
  // required by active-resource
  // https://github.com/nicklandgrebe/active-resource.js/issues/47
  static className = "Fee";
  static queryName = "fees";

  // required by @casl/ability
  // https://stackoverflow.com/questions/57677510/casl-is-not-working-properly-in-vue-production-mode
  static modelName = "Fee";

  static define() {
    this.attributes("voucherHash", "totalCents");
  }

  toString() {
    return `#${this.id}`;
  }

  decodedHtmlWidget() {
    return atob(this.htmlWidget);
  }
}

export default APILibrary.createResource(Fee);
