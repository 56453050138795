var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-simple-table", [
    _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Descripción")]),
        _vm._v(" "),
        _c("th", [_vm._v("Cantidad")]),
        _vm._v(" "),
        _c("th", [_vm._v("Importe")])
      ])
    ]),
    _vm._v(" "),
    _c("tbody", [
      _c("tr", [
        _c("td", [_vm._v("Caracteres / Espacios")]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.announcement.charCount))]),
        _vm._v(" "),
        _c("td", { staticClass: "text-right" }, [
          _vm._v("$" + _vm._s(_vm.announcement.charCount * 1.3))
        ])
      ]),
      _vm._v(" "),
      _vm.announcement.imageCount > 0
        ? _c("tr", [
            _c("td", [_vm._v("Imágenes")]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.announcement.imageCount))]),
            _vm._v(" "),
            _c("td", { staticClass: "text-right" }, [
              _vm._v("$" + _vm._s(_vm.announcement.imageCount * 1.3))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.announcement.annexPageCount > 0
        ? _c("tr", [
            _c("td", [_vm._v("Anexos (páginas)")]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.announcement.annexPageCount))]),
            _vm._v(" "),
            _c("td", { staticClass: "text-right" }, [
              _vm._v(_vm._s(_vm.announcement.annexPageCount))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("tr", [
        _c("td", { attrs: { colspan: "2" } }, [_vm._v("Total")]),
        _vm._v(" "),
        _c("td", { staticClass: "text-right" }, [
          _vm._v("$" + _vm._s(_vm.announcement.totalBudget))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }