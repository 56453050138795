// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start();
// require("@rails/activestorage").start();
// require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Vue from "vue";

import App from "@/components/public/App";

import axios from "@/plugins/axios";
import fragment from "@/plugins/fragment";
import store from "@/plugins/store";
import toast from "@/plugins/toasted";
import vuetify from "@/plugins/vuetify";

import i18n from "@/plugins/public/i18n";
import routes from "@/plugins/public/routes";
import "@/plugins/public/autoloading";

import "@/plugins/bus";
import "@/plugins/vee-validate";
import "@/plugins/portal";
import "@/plugins/ckeditor";
import "@/plugins/bytes";
import "@/plugins/vue-file-inputs";

import "@/assets/favicon.ico";

// Use packages
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

document.addEventListener("DOMContentLoaded", () => {
  Vue.prototype.$config = JSON.parse(
    document.getElementById("app").dataset.config
  );

  const app = new Vue({
    vuetify,
    toast,
    fragment,
    i18n,
    router: routes,
    store: store,
    render: h => h(App)
  }).$mount("#app");

  document.body.appendChild(app.$el);
});
