var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "3" } },
                [
                  _c("v-img", {
                    staticClass: "gba-logo",
                    attrs: {
                      src: _vm.IsologotipoCPositivo,
                      height: "410",
                      "max-width": "220",
                      contain: "",
                      title: "Gobierno de la Provincia de Buenos Aires",
                      alt: "Gobierno de la Provincia de Buenos Aires"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "12", md: "6", lg: "3" } }, [
                _c("div", { staticClass: "responsive-margin" }, [
                  _c("h4", [_vm._v("Redes Sociales")]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "list-icons" },
                    _vm._l(_vm.socialLinks, function(link) {
                      return _c("li", { key: link.title }, [
                        _c("a", {
                          class: link.class,
                          attrs: {
                            href: link.url,
                            title: link.title,
                            target: "_blank",
                            rel: "nofollow noreferrer"
                          }
                        })
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Contacto")]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "list-icons" }, [
                    _c("li", [
                      _c("a", {
                        staticClass: "icon icon-contact",
                        attrs: {
                          href: "https://www.gba.gob.ar/contacto",
                          title: "Contacto",
                          target: "_blank",
                          rel: "nofollow noreferrer"
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Autoridad de Aplicación")]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "list-links" }, [
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://gba.gob.ar/sg",
                            title: "Secretaría General"
                          }
                        },
                        [_vm._v("Secretaría General")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Subsecretaría Legal y Técnica")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "12", md: "6", lg: "3" } }, [
                _c("div", { staticClass: "responsive-margin" }, [
                  _c("h4", [_vm._v("Guía de Servicios")]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "list-links" },
                    _vm._l(_vm.serviceLinks, function(link) {
                      return _c("li", { key: link.title }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: link.url,
                              title: link.title,
                              target: "_blank",
                              rel: "nofollow noreferrer"
                            }
                          },
                          [_vm._v(_vm._s(link.title))]
                        )
                      ])
                    }),
                    0
                  )
                ])
              ]),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "12", md: "6", lg: "3" } }, [
                _c("div", { staticClass: "responsive-margin" }, [
                  _c("h4", [_vm._v("Uso Interno")]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "list-links" },
                    _vm._l(_vm.internalLinks, function(link) {
                      return _c("li", { key: link.title }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: link.url,
                              title: link.title,
                              target: "_blank",
                              rel: "nofollow noreferrer"
                            }
                          },
                          [_vm._v(_vm._s(link.title))]
                        )
                      ])
                    }),
                    0
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }