<template>
  <fragment>
    <article>
      <h2 class="mb-2 text-uppercase">
        {{ $t("views.announcements.new.title") }}
      </h2>

      <Form />
    </article>
  </fragment>
</template>

<script>
import Form from "@/components/public/announcements/Form";

export default {
  components: {
    Form,
  },
};
</script>
