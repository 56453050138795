var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fragment", [
    _c(
      "article",
      [
        _c("h2", { staticClass: "mb-2 text-uppercase" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("views.announcements.new.title")) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("Form")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }