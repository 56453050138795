<template>
  <v-footer>
    <v-container>
      <v-row>
        <v-col cols="12" md="6" lg="3">
          <v-img
            :src="IsologotipoCPositivo"
            class="gba-logo"
            height="410"
            max-width="220"
            contain
            title="Gobierno de la Provincia de Buenos Aires"
            alt="Gobierno de la Provincia de Buenos Aires"
          ></v-img>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <div class="responsive-margin">
            <h4>Redes Sociales</h4>
            <ul class="list-icons">
              <li v-for="link in socialLinks" :key="link.title">
                <a
                  :class="link.class"
                  :href="link.url"
                  :title="link.title"
                  target="_blank"
                  rel="nofollow noreferrer"
                />
              </li>
            </ul>
            <h4>Contacto</h4>
            <ul class="list-icons">
              <li>
                <a
                  class="icon icon-contact"
                  href="https://www.gba.gob.ar/contacto"
                  title="Contacto"
                  target="_blank"
                  rel="nofollow noreferrer"
                />
              </li>
            </ul>
            <h4>Autoridad de Aplicación</h4>
            <ul class="list-links">
              <li>
                <a href="https://gba.gob.ar/sg" title="Secretaría General"
                  >Secretaría General</a
                >
              </li>
              <li>Subsecretaría Legal y Técnica</li>
            </ul>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <div class="responsive-margin">
            <h4>Guía de Servicios</h4>
            <ul class="list-links">
              <li v-for="link in serviceLinks" :key="link.title">
                <a
                  :href="link.url"
                  :title="link.title"
                  target="_blank"
                  rel="nofollow noreferrer"
                  >{{ link.title }}</a
                >
              </li>
            </ul>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <div class="responsive-margin">
            <h4>Uso Interno</h4>
            <ul class="list-links">
              <li v-for="link in internalLinks" :key="link.title">
                <a
                  :href="link.url"
                  :title="link.title"
                  target="_blank"
                  rel="nofollow noreferrer"
                  >{{ link.title }}</a
                >
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import IsologotipoCPositivo from "@/assets/images/isologotipo_c_positivo.svg";

export default {
  components: {},
  data: () => {
    return {
      IsologotipoCPositivo,
      socialLinks: [
        {
          title: "Facebook",
          class: "icon icon-facebook",
          url: "https://www.facebook.com/BAProvincia/",
        },
        {
          title: "Twitter",
          class: "icon icon-twitter",
          url: "https://twitter.com/baprovincia",
        },
        {
          title: "Instagram",
          class: "icon icon-instagram",
          url: "https://www.instagram.com/provinciaba/",
        },
        {
          title: "YouTube",
          class: "icon icon-youtube",
          url: "https://www.youtube.com/channel/UCRuY8kHZHaiqAAdjcgobsNw",
        },
      ],
      serviceLinks: [
        {
          title: "Portal de trámites",
          url: "https://portal.gba.gob.ar/web/portal/",
        },
        {
          title: "Expedientes",
          url: "https://sistemas.gba.gov.ar/consulta/expedientes/index.php",
        },
        {
          title: "Seguridad Vial",
          url: "https://www.gba.gob.ar/seguridadvial",
        },
        { title: "ARBA", url: "https://www.arba.gov.ar/" },
        {
          title: "Boletín Oficial",
          url: "https://www.boletinoficial.gba.gob.ar/",
        },
        {
          title: "Registro de las Personas",
          url: "https://www.gba.gob.ar/registrodelaspersonas",
        },
        {
          title: "Contrataciones",
          url: "https://www.gba.gob.ar/contrataciones",
        },
        { title: "Ver Todos", url: "https://www.gba.gob.ar/servicios" },
        {
          title: "Políticas de privacidad",
          url: "https://www.gba.gob.ar/Politicasdeprivacidad",
        },
      ],
      internalLinks: [
        { title: "Webmail (gba.gob.ar)", url: "https://mail.gba.gob.ar/" },
        { title: "Webmail HORDE", url: "https://webmail.gba.gob.ar/" },
        {
          title: "Sistemas",
          url: "https://sistemas.gba.gob.ar/login/login.php",
        },
        { title: "GDEBA", url: "https://www.gdeba.gba.gob.ar/" },
        {
          title: "Portal del Empleado",
          url: "https://portal.rrhh.gba.gob.ar/portal/login",
        },
        { title: "Mesa de Ayuda", url: "https://www.gba.gob.ar/mesadeayuda" },
        {
          title: "SIAPE",
          url:
            "https://sso.siape.gba.gov.ar/loginsso/login.jsp?site2pstoretoken=v1.4~5312A8AE~95575D5DF626EAACF82420F5D585CC6E80B8C88B92E7C8B123F5CF245E303B41D404E94B063AA10F89907B7091F9EC202FC5279A676DB343861289278BE8688679381560092CF0A56968C3020C90C9C2EE402FA88348FB355394C99FDB982809FCE67A345A8A2A8FA7E1E0454B23721E2D539B8C852199246D87512AEB8C1A4A76B42B787CECA3D7E276E8B406511B31D2003EA8709AAF552A6FCA0A01240E07F84CF6A505B0FF2C7214AF31533EC1E4FC34FBD32B0A8CD43AEA48F9206EAF6C",
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/stylesheets/footer";
</style>
