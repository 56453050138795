export default {
  esAR: {
    views: {
      actions: {
        show: "Ver",
        edit: "Editar",
        new: "Nuevo",
        destroy: "Borrar definitivamente",
        reallyDestroy: "Borrar definitivamente",
        undiscard: "Restaurar",
        discard: "Borrar",
        save: "Guardar",
        reset: "Limpiar"
      },
      announcements: {
        new: {
          title: "Crear anuncio"
        },
        actions: {
          addAnnex: "Agregar anexo"
        }
      },
      announcements_lite: {
        budget: {
          title: "Presupuestá tu aviso"
        },
        form: {
          success: "Su presupuesto ha sido generado con éxito.",
          error: "Ocurrió un error al generar su presupuesto. Por favor, intente nuevamente más tarde."
        }
      }
    },
    attributes: {
      announcement: {
        annexes: "Anexos",
        authorEmail: "Correo electrónico"
      },
      annex: {
        name: "Nombre",
        file: "Archivo"
      }
    }
  }
};
