<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <Form />
      </v-col>
    </v-row>
    <v-row>
    </v-row>
  </v-container>
</template>

<script>
import Form from "@/components/public/announcements_lite/Form";

export default {
  components: {
    Form
  }
};
</script>
