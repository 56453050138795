<template>
  <v-app-bar app elevation="0" color="primary" height="90">
    <a href="https://gba.gob.ar" title="Gobierno de la Provincia de Buenos Aires" target="_blank">
      <v-img
        contain
        :src="IsologotipoBBlanco"
        title="Gobierno de la Provincia de Buenos Aires"
        alt="Gobierno de la Provincia de Buenos Aires"
        height="90"
        width="200"
      ></v-img>
    </a>
    <a href="https://boletinoficial.gba.gob.ar" title="Boletín Oficial de la Provincia de Buenos Aires" target="_blank">
      <v-img
        contain
        :src="BoLogotipoBlanco"
        title="Boletín Oficial de la Provincia de Buenos Aires"
        alt="Boletín Oficial de la Provincia de Buenos Aires"
        height="60"
        width="160"
      ></v-img>
    </a>
  </v-app-bar>
</template>

<script>
import IsologotipoBBlanco from "@/assets/images/isologotipo_b_blanco.svg";
import BoLogotipoBlanco from "@/assets/images/bo_logotipo_blanco.svg";

export default {
  name: "Navbar",
  data: () => {
    return {
      IsologotipoBBlanco,
      BoLogotipoBlanco,
      drawer: false,
    };
  },
};
</script>
