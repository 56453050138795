<template>
  <fragment>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(onSubmit)">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="object.email"
                :label="$t('attributes.announcement.authorEmail')"
                required
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ckeditor
                v-model="object.content"
                :editor="editor"
                :config="editorConfig"
              ></ckeditor>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" md="8">
              <h3 class="text-uppercase">
                {{ $t("attributes.announcement.annexes") }}
              </h3>

              <TusVueFileAgentMultipleProvider
                vid="annexes"
                :label="$t('attributes.annex.file')"
                :resources="announcement.annexes().target().__collection"
                field="file"
                :accept="accept"
                max-size="100MB"
                :filename="
                  resource => `${resource.name}.${resource.file.extension}`
                "
                @file:uploaded="file => addAnnex(file)"
                @file:renamed="file => renameAnnex(file)"
                @file:deleted="file => removeAnnex(file)"
                @files:uploading="disableSubmit()"
                @files:uploaded="enableSubmit()"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-btn text class="mr-4" color="success" type="button">
                {{ $t("views.actions.discard") }}
              </v-btn>
              <SaveButton :loading="loading" :disabled="disabled" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
  </fragment>
</template>

<script>
import Announcement from "@/resources/Announcement";
import Annex from "@/resources/Annex";

import CustomEditor from "@/vendor/custom-ckeditor";

import TusVueFileAgentMultipleProvider from "./TusVueFileAgentMultipleProvider";

export default {
  components: {
    TusVueFileAgentMultipleProvider
  },
  props: {
    announcement: {
      type: Announcement,
      default: () => Announcement.build()
    }
  },
  data: ({ announcement }) => {
    return {
      loading: false,
      disabled: false,
      object: announcement.attributes(),
      editor: CustomEditor,
      editorConfig: {},
      annexesAttributes: Object.assign([], announcement.annexesAttributes),
      accept: [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "image/jpeg",
        "image/png"
      ].join(",")
    };
  },
  methods: {
    addAnnex({ fileId, name }) {
      const annex = Annex.build({
        file: fileId,
        name
      });

      this.annexesAttributes.push(
        Object.assign({ _destroy: 0 }, annex.attributes())
      );
    },
    findAnnex(index) {
      let annexes = this.annexesAttributes.filter(
        annex => annex._destroy == undefined || annex._destroy == 0
      );

      return annexes[index];
    },
    removeAnnex({ index }) {
      this.findAnnex(index)._destroy = 1;
    },
    renameAnnex({ index, name }) {
      this.findAnnex(index).name = name;
    },
    disableSubmit() {
      this.disabled = true;
    },
    enableSubmit() {
      this.disabled = false;
    },
    async onSubmit() {
      this.loading = true;

      if (this.$refs.form.validate()) {
        this.announcement.assignAttributes({
          ...this.object,
          annexesAttributes: this.annexesAttributes
        });

        try {
          await this.announcement.save();

          this.loading = false;

          this.$router.push({
            name: "public-announcements-show",
            params: { uuid: this.announcement.uuid }
          });
        } catch {
          this.loading = false;

          this.$refs.form.setErrors(this.announcement.getErrors());
        }
      }
    }
  }
};
</script>
