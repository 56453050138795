import Vue from "vue";
import bytes from "bytes";

Vue.prototype.$bytesParse = function(size) {
  return bytes.parse(size);
};

Vue.prototype.$bytesFormat = function(size) {
  return bytes.format(size, { decimalPlaces: 0 });
};
