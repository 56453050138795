import { render, staticRenderFns } from "./Budget.vue?vue&type=template&id=b1ee8e64&"
import script from "./Budget.vue?vue&type=script&lang=js&"
export * from "./Budget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/avisos-bo/shared/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b1ee8e64')) {
      api.createRecord('b1ee8e64', component.options)
    } else {
      api.reload('b1ee8e64', component.options)
    }
    module.hot.accept("./Budget.vue?vue&type=template&id=b1ee8e64&", function () {
      api.rerender('b1ee8e64', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/pages/public/announcements_lite/Budget.vue"
export default component.exports