import APILibrary from "@/resources/APILibrary";
import BaseResource from "@/resources/BaseResource";
import Fee from "@/resources/Fee";

import "./Annex";

class Announcement extends BaseResource {
  // required by active-resource
  // https://github.com/nicklandgrebe/active-resource.js/issues/47
  static className = "Announcement";
  static queryName = "announcements";

  // required by @casl/ability
  // https://stackoverflow.com/questions/57677510/casl-is-not-working-properly-in-vue-production-mode
  static modelName = "Announcement";

  static define() {
    this.attributes(
      "email",
      "content",
      "charCount",
      "imageCount",
      "totalBudget",
      "state",
      "createdAt",
      "updatedAt",
      "annexesAttributes"
    );

    this.hasMany("annexes");
  }

  toString() {
    return `#${this.id}`;
  }

  generateFee() {
    console.log("generateFee");
    console.log(this.links().fee);
  }
}

export default APILibrary.createResource(Announcement);
