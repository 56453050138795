<template>
  <v-simple-table>
    <thead>
      <tr>
        <th>Descripción</th>
        <th>Cantidad</th>
        <th>Importe</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Caracteres / Espacios</td>
        <td>{{ announcement.charCount }}</td>
        <td class="text-right">${{ announcement.charCount * 1.30 }}</td>
      </tr>
      <tr v-if="announcement.imageCount > 0">
        <td>Imágenes</td>
        <td>{{ announcement.imageCount }}</td>
        <td class="text-right">${{ announcement.imageCount * 1.30 }}</td>
      </tr>
      <tr v-if="announcement.annexPageCount > 0">
        <td>Anexos (páginas)</td>
        <td>{{ announcement.annexPageCount }}</td>
        <td class="text-right">{{ announcement.annexPageCount }}</td>
      </tr>
      <tr>
        <td colspan="2">Total</td>
        <td class="text-right">${{ announcement.totalBudget }}</td>
      </tr>
    </tbody>
  </v-simple-table>  
</template>

<script>
export default {
  props: {
    announcement: {
      type: Object,
      required: true
    }
  },  
}
</script>