var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { app: "", elevation: "0", color: "primary", height: "90" } },
    [
      _c(
        "a",
        {
          attrs: {
            href: "https://gba.gob.ar",
            title: "Gobierno de la Provincia de Buenos Aires",
            target: "_blank"
          }
        },
        [
          _c("v-img", {
            attrs: {
              contain: "",
              src: _vm.IsologotipoBBlanco,
              title: "Gobierno de la Provincia de Buenos Aires",
              alt: "Gobierno de la Provincia de Buenos Aires",
              height: "90",
              width: "200"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://boletinoficial.gba.gob.ar",
            title: "Boletín Oficial de la Provincia de Buenos Aires",
            target: "_blank"
          }
        },
        [
          _c("v-img", {
            attrs: {
              contain: "",
              src: _vm.BoLogotipoBlanco,
              title: "Boletín Oficial de la Provincia de Buenos Aires",
              alt: "Boletín Oficial de la Provincia de Buenos Aires",
              height: "60",
              width: "160"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }