var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      attrs: {
        border: "left",
        "colored-border": "",
        color: "primary",
        elevation: "2",
        tile: ""
      }
    },
    [
      _c("h2", [_vm._v("Presupuesto")]),
      _vm._v(" "),
      _c("v-simple-table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("Concepto")]),
            _vm._v(" "),
            _c("th", [_vm._v("Cantidad")]),
            _vm._v(" "),
            _c("th", [_vm._v("Tasa por unidad*")]),
            _vm._v(" "),
            _c("th", { staticStyle: { "text-align": "right" } }, [
              _vm._v("Subtotal")
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _c("tr", [
              _c("td", [_vm._v("Caracteres/espacios")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.announcement.charCount))]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.monetize(_vm.announcement.charPrice)))
              ]),
              _vm._v(" "),
              _c("td", { attrs: { align: "right" } }, [
                _vm._v(_vm._s(_vm.monetize(_vm.announcement.charTotal)))
              ])
            ]),
            _vm._v(" "),
            _vm.announcement.imageCount > 0
              ? _c("tr", [
                  _c("td", [_vm._v("Imágenes")]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.announcement.imageCount))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(_vm.monetize(_vm.announcement.imagePrice)))
                  ]),
                  _vm._v(" "),
                  _c("td", { attrs: { align: "right" } }, [
                    _vm._v(_vm._s(_vm.monetize(_vm.announcement.imageTotal)))
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.announcement.annexesPages, function(annex) {
              return _c("tr", { key: annex }, [
                _c("td", [_vm._v("Páginas del anexo " + _vm._s(annex.name))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(annex.pageCount))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(_vm.monetize(_vm.announcement.annexPagePrice)))
                ]),
                _vm._v(" "),
                _c("td", { attrs: { align: "right" } }, [
                  _vm._v(_vm._s(_vm.monetize(annex.totalCents)))
                ])
              ])
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("tfoot", [
          _c("tr", [
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", [_c("strong", [_vm._v("Total")])]),
            _vm._v(" "),
            _c("td", { attrs: { align: "right" } }, [
              _c("strong", [
                _vm._v(_vm._s(_vm.monetize(_vm.announcement.totalCents)))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [
              _c("em", [
                _vm._v("*según\n            "),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://normas.gba.gob.ar/ar-b/ley/2020/15226/222268",
                      target: "_blank",
                      title: "Ley 15.226"
                    }
                  },
                  [_vm._v("Ley 15.226")]
                )
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }