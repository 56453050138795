var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _c("ValidationObserver", {
        ref: "form",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "v-form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      }
                    }
                  },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: _vm.$t(
                                      "attributes.announcement.authorEmail"
                                    ),
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.object.email,
                                    callback: function($$v) {
                                      _vm.$set(_vm.object, "email", $$v)
                                    },
                                    expression: "object.email"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("ckeditor", {
                                  attrs: {
                                    editor: _vm.editor,
                                    config: _vm.editorConfig
                                  },
                                  model: {
                                    value: _vm.object.content,
                                    callback: function($$v) {
                                      _vm.$set(_vm.object, "content", $$v)
                                    },
                                    expression: "object.content"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "6", md: "8" } },
                              [
                                _c("h3", { staticClass: "text-uppercase" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "attributes.announcement.annexes"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("TusVueFileAgentMultipleProvider", {
                                  attrs: {
                                    vid: "annexes",
                                    label: _vm.$t("attributes.annex.file"),
                                    resources: _vm.announcement
                                      .annexes()
                                      .target().__collection,
                                    field: "file",
                                    accept: _vm.accept,
                                    "max-size": "100MB",
                                    filename: function(resource) {
                                      return (
                                        resource.name +
                                        "." +
                                        resource.file.extension
                                      )
                                    }
                                  },
                                  on: {
                                    "file:uploaded": function(file) {
                                      return _vm.addAnnex(file)
                                    },
                                    "file:renamed": function(file) {
                                      return _vm.renameAnnex(file)
                                    },
                                    "file:deleted": function(file) {
                                      return _vm.removeAnnex(file)
                                    },
                                    "files:uploading": function($event) {
                                      return _vm.disableSubmit()
                                    },
                                    "files:uploaded": function($event) {
                                      return _vm.enableSubmit()
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-4",
                                    attrs: {
                                      text: "",
                                      color: "success",
                                      type: "button"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("views.actions.discard")
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("SaveButton", {
                                  attrs: {
                                    loading: _vm.loading,
                                    disabled: _vm.disabled
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }