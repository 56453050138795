<template>
  <fragment>
    <ValidationProvider
      v-slot="{ errors }"
      ref="validator"
      :vid="vid"
      :rules="{
        size: $bytesParse(maxSize) / 1024, // the validation in vee-validate is in kilobytes, bytes converts to bytes
        mimes: accept.split(',')
      }"
    >
      <VueFileAgent
        ref="vueFileAgent"
        v-model="internalValue"
        :accept="accept"
        :deletable="deletable"
        :editable="editable"
        :disabled="disabled"
        :multiple="true"
        :linkable="true"
        :compact="false"
        :theme="theme"
        :meta="true"
        :max-size="maxSize"
        :error-text="{
          type: 'Tipo de archivo invalido',
          size: `El archivo excede el tamamaño maximo ${maxSize}`
        }"
        help-text="Elige o arrastra un archivo hasta aquí"
        @select="uploadFile($event)"
        @beforedelete="beforeDeleteFile($event)"
        @delete="deleteFile($event)"
        @rename="renameFile($event)"
      >
        <template #after-outer>
          <div v-if="errors.length > 0">
            <div class="error--text">
              {{ errors.join(", ") }}
            </div>
          </div>
        </template>
      </VueFileAgent>
    </ValidationProvider>
  </fragment>
</template>

<script>
import * as tus from "tus-js-client";
import { ValidationProvider } from "vee-validate";

export default {
  name: "TusVueFileAgentProvider",
  components: {
    ValidationProvider
  },
  props: {
    vid: {
      type: String,
      required: true
    },
    accept: {
      type: String,
      required: true
    },
    maxSize: {
      type: String,
      required: true
    },
    resources: {
      type: Array,
      default: () => []
    },
    field: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      default: null
    },
    filename: {
      type: Function,
      required: true
    },
    theme: {
      type: String,
      default: "default"
    },
    editable: {
      type: Boolean,
      default: true
    },
    deletable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  data: ({ resources, field, value, filename }) => {
    let internalValue = [];

    let populateResource = function(resource) {
      if (resource[field] && Object.keys(resource[field]).length > 0) {
        internalValue.push({
          ...resource[field],
          name: filename(resource),
          url: resource.links()[field],
          resourceId: resource.id
        });
      }
    };

    if (value === null) {
      resources.forEach(populateResource);
    } else {
      internalValue = value;
    }

    return {
      internalValue,
      inputState: "initial",
      validate: null
    };
  },
  watch: {
    value: function(newValue) {
      this.internalValue = newValue;
    }
  },
  mounted() {
    this.validate = this.$refs.validator.validate;
  },
  methods: {
    async uploadFile(fileRecords) {
      for (const fileRecord of fileRecords) {
        const response = await this.validate(fileRecord.file);

        if (response.valid) {
          this.setFile(fileRecord);
        } else {
          this.inputState = "error";
        }
      }
    },
    beforeDeleteFile(fileRecord) {
      if (this.uploaded(fileRecord)) {
        const event = this.fileRecordEvent(fileRecord);

        this.$emit("file:deleted", event);
      }

      this.$refs.vueFileAgent.deleteFileRecord(fileRecord);

      this.emitFilesUploaded();
    },
    deleteFile() {},
    renameFile(fileRecord) {
      if (this.uploaded(fileRecord)) {
        const event = this.fileRecordEvent(fileRecord);

        this.$emit("file:renamed", event);
      }
    },
    uploaded(fileRecord) {
      return fileRecord.resourceId || fileRecord.uploaded;
    },
    uploadingFiles() {
      return !!this.internalValue.filter(element => element.uploaded == false)
        .length;
    },
    emitFilesUploaded() {
      if (!this.uploadingFiles()) {
        this.$emit("files:uploaded");
      }
    },
    async setFile(fileRecord) {
      this.$emit("files:uploading");

      fileRecord.uploaded = false;

      const upload = new tus.Upload(fileRecord.file, {
        endpoint: "/files/",
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          name: fileRecord.file.name,
          type: fileRecord.file.type
        },
        onError: function(error) {
          console.log(error);
        }.bind(this),
        onProgress: function(bytesUploaded, bytesTotal) {
          fileRecord.progress(
            Math.min(Math.floor((bytesUploaded / bytesTotal) * 100), 99)
          );
        }.bind(this),
        onSuccess: function() {
          const fileId = upload.url.split("/").pop();

          fileRecord.progress(100);

          this.$emit("file:uploaded", this.fileRecordEvent(fileRecord, fileId));

          fileRecord.uploaded = true;

          this.emitFilesUploaded();
        }.bind(this)
      });

      upload.start();
    },
    fileRecordEvent(fileRecord, fileId = null) {
      const name = fileRecord
        .name()
        .split(".")
        .slice(0, -1)
        .join(".");

      return {
        index: this.internalValue.indexOf(fileRecord),
        fileRecord,
        name,
        fileId
      };
    }
  }
};
</script>
