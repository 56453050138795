<template>
  <v-app>
    <Navbar />

    <v-main>
      <router-view />
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import Navbar from "@/components/public/base/Navbar";
import Footer from "@/components/public/base/Footer";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/_variables.scss";

.v-application {
  font-family: $primary-font-family;
}

// h1,
// h2 {
//   font-weight: 600;
// }
</style>
