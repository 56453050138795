import APILibrary from "@/resources/APILibrary";
import BaseResource from "@/resources/BaseResource";

class AnnouncementLite extends BaseResource {
  // required by active-resource
  // https://github.com/nicklandgrebe/active-resource.js/issues/47
  static className = "AnnouncementLite";
  static queryName = "announcement_lites";

  // required by @casl/ability
  // https://stackoverflow.com/questions/57677510/casl-is-not-working-properly-in-vue-production-mode
  static modelName = "AnnouncementLite";

  static define() {
    this.attributes(
      "content",
      "charCount",
      "totalCents",
      "imageCount",
      "imagePrice",
      "annexCount",
      "annexes",
      "annexesPages",
      "recaptchaToken",
      "createdAt",
      "updatedAt"
    );
  }

  toString() {
    return `#${this.id}`;
  }
}

export default APILibrary.createResource(AnnouncementLite);
