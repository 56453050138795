<template>
  <v-alert border="left" colored-border color="primary" elevation="2" tile>
    <h2>Presupuesto</h2>
    <v-simple-table>
      <thead>
        <tr>
          <th>Concepto</th>
          <th>Cantidad</th>
          <th>Tasa por unidad*</th>
          <th style="text-align: right">Subtotal</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Caracteres/espacios</td>
          <td>{{ announcement.charCount }}</td>
          <td>{{ monetize(announcement.charPrice) }}</td>
          <td align="right">{{ monetize(announcement.charTotal) }}</td>
        </tr>
        <tr v-if="announcement.imageCount > 0">
          <td>Imágenes</td>
          <td>{{ announcement.imageCount }}</td>
          <td>{{ monetize(announcement.imagePrice) }}</td>
          <td align="right">{{ monetize(announcement.imageTotal) }}</td>
        </tr>
        <tr v-for="annex in announcement.annexesPages" :key="annex">
          <td>Páginas del anexo {{ annex.name }}</td>
          <td>{{ annex.pageCount }}</td>
          <td>{{ monetize(announcement.annexPagePrice) }}</td>
          <td align="right">{{ monetize(annex.totalCents) }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td></td>
          <td><strong>Total</strong></td>
          <td align="right">
            <strong>{{ monetize(announcement.totalCents) }}</strong>
          </td>
        </tr>
        <tr>
          <td>
            <em
              >*según
              <a
                href="https://normas.gba.gob.ar/ar-b/ley/2020/15226/222268"
                target="_blank"
                title="Ley 15.226"
                >Ley 15.226</a
              ></em
            >
          </td>
        </tr>
      </tfoot>
    </v-simple-table>
  </v-alert>
</template>

<script>
export default {
  props: {
    announcement: {
      type: Object,
      required: true,
    },
  },
  beforeDestroy: () => {
    this.announcement.destroy();
  },
  methods: {
    monetize(val) {
      return new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
      }).format(val / 100.0);
    },
  },
};
</script>
