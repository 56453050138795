<template>
  <v-btn
    tile
    class="mr-4"
    color="success"
    :loading="loading"
    :disabled="disabled"
    type="submit"
  >
    {{ text ? text : $t("views.actions.save") }}
  </v-btn>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: null
    }
  }
};
</script>
