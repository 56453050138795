var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _c("ValidationProvider", {
        ref: "validator",
        attrs: {
          vid: _vm.vid,
          rules: {
            size: _vm.$bytesParse(_vm.maxSize) / 1024, // the validation in vee-validate is in kilobytes, bytes converts to bytes
            mimes: _vm.accept.split(",")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var errors = ref.errors
              return [
                _c("VueFileAgent", {
                  ref: "vueFileAgent",
                  attrs: {
                    accept: _vm.accept,
                    deletable: _vm.deletable,
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    multiple: true,
                    linkable: true,
                    compact: false,
                    theme: _vm.theme,
                    meta: true,
                    "max-size": _vm.maxSize,
                    "error-text": {
                      type: "Tipo de archivo invalido",
                      size:
                        "El archivo excede el tamamaño maximo " + _vm.maxSize
                    },
                    "help-text": "Elige o arrastra un archivo hasta aquí"
                  },
                  on: {
                    select: function($event) {
                      return _vm.uploadFile($event)
                    },
                    beforedelete: function($event) {
                      return _vm.beforeDeleteFile($event)
                    },
                    delete: function($event) {
                      return _vm.deleteFile($event)
                    },
                    rename: function($event) {
                      return _vm.renameFile($event)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "after-outer",
                        fn: function() {
                          return [
                            errors.length > 0
                              ? _c("div", [
                                  _c("div", { staticClass: "error--text" }, [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(errors.join(", ")) +
                                        "\n          "
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.internalValue,
                    callback: function($$v) {
                      _vm.internalValue = $$v
                    },
                    expression: "internalValue"
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }