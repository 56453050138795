import ClassicEditorBase from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
// import UploadAdapterPlugin from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter";
// import AutoformatPlugin from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
// import BlockQuotePlugin from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import EasyImagePlugin from "@ckeditor/ckeditor5-easy-image/src/easyimage";
// import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading";
import ImagePlugin from "@ckeditor/ckeditor5-image/src/image";
import ImageCaptionPlugin from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStylePlugin from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbarPlugin from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUploadPlugin from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResizePlugin from "@ckeditor/ckeditor5-image/src/imageresize";
// import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ListPlugin from "@ckeditor/ckeditor5-list/src/list";
// import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";

import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import AlignmentPlugin from "@ckeditor/ckeditor5-alignment/src/alignment";

export default class CustomEditor extends ClassicEditorBase {}

CustomEditor.builtinPlugins = [
  EssentialsPlugin,
  // UploadAdapterPlugin,
  // AutoformatPlugin,
  BoldPlugin,
  ItalicPlugin,
  AlignmentPlugin,
  //   BlockQuotePlugin,
  EasyImagePlugin,
  // HeadingPlugin,
  ImagePlugin,
  // ImageCaptionPlugin,
  ImageStylePlugin,
  ImageToolbarPlugin,
  ImageUploadPlugin,
  ImageResizePlugin,
  //   LinkPlugin,
  ListPlugin,
  //   ParagraphPlugin,
  Base64UploadAdapter
];

CustomEditor.defaultConfig = {
  toolbar: {
    items: [
      //   "heading",
      //   "|",
      "bold",
      "italic",
      //   "link",
      "alignment",
      "bulletedList",
      "numberedList",
      "imageUpload",
      //   "blockQuote",
      "|",
      "undo",
      "redo"
    ]
  },
  image: {
    caption: {
      enable: false
    },
    styles: [
      // This option is equal to a situation where no style is applied.
      "full",

      // This represents an image aligned to the left.
      "alignLeft",

      // This represents an image aligned to the right.
      "alignRight",

      "alignCenter"

      // "side"
    ],
    //   resizeUnit: "%",
    //   resizeOptions: [
    //     {
    //       name: "imageResize:original",
    //       value: null
    //     },
    //     {
    //       name: "imageResize:25",
    //       value: "25"
    //     },
    //     {
    //       name: "imageResize:50",
    //       value: "50"
    //     },
    //     {
    //       name: "imageResize:75",
    //       value: "75"
    //     },
    //     {
    //       name: "imageResize:100",
    //       value: "100"
    //     }
    //   ],
    toolbar: [
      "imageStyle:full",
      "imageStyle:alignLeft",
      "imageStyle:alignCenter",
      "imageStyle:alignRight",
      "|",
      "imageResize"
      // "|",
      // "imageTextAlternative"
    ]
  },
  language: "es"
};
