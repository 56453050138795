<template>
  <fragment>
    <LoadingIcon v-if="object == null" />
    <slot v-else />
  </fragment>
</template>

<script>
export default {
  props: {
    object: {
      type: [Array, Object],
      default: null,
    },
  },
}
</script>