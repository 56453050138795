var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "mr-4",
      attrs: {
        tile: "",
        color: "success",
        loading: _vm.loading,
        disabled: _vm.disabled,
        type: "submit"
      }
    },
    [
      _vm._v(
        "\n  " +
          _vm._s(_vm.text ? _vm.text : _vm.$t("views.actions.save")) +
          "\n"
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }